export const API_LIST = {
  login: '/admin/login',
  logout: '/admin/logout',
  register: '/admin/register',
  auth: {
    changePass: '/admin/reset-valid',
    forgetPass: '/admin/forget-pass',
  },
  get: {
    admins: '/admin',
    roleList: '/admin/role',
    permissionList: '/admin/permission',
    actionLogs: '/admin/action-logs',
    users: '/admin/user',
    userDetails: '/admin/user/profile-get',
    userCreditTrans: '/admin/user/transaction',
    userCashTrans: '/admin/cash/transaction-list',
    competitionList: '/admin/competition/list',
    competitionDetails: '/admin/competition/details',
    quizTypes: '/admin/competition/quiz-type',
    quizList: '/admin/competition/quiz-list',
    shortName: '/admin/competition/short-name',
    ticketList: '/admin/competition/ticket',
    prizeList: '/admin/competition/competition-rewards',
    shuffleTickets: '/admin/competition/shuffle-tickets',
    popupInfo: '/admin/competition/popup-info',
    compApprovalTriggers: '/admin/competition/audit-triggers',
    compApprovalLogs: '/admin/competition/audit-logs',
    catalogList: '/admin/catalog/catalogs',
    catalogCompetitions: '/admin/catalog/competitions',
    productList: '/admin/product/list',
    productDetails: '/admin/product/details',
    productInventory: '/admin/product/inventory',
    invoices: '/admin/product/invoices',
    attributeList: '/admin/attribute',
    productEntityList: '/admin/attribute/entity-type',
    orderList: '/admin/order/list',
    orderDetails: '/admin/order/details',
    emailList: '/admin/order/email',
    couponList: '/admin/coupon/list',
    couponCode: '/admin/coupon/code-list',
    entryList: '/admin/entry-list/list',
    videoDrawList: '/admin/draw-list/list',
    winnerList: '/admin/user-reward/list',
    winnerDetails: '/admin/user-reward/detail',
    notificationList: '/admin/notification/list',
    donationList: '/admin/donation/list',
    salesReport: '/admin/report/sales',
    competitionReport: '/admin/report/competitions',
    competitionDailyReport: '/admin/report/competition-daily',
    usersReport: '/admin/report/users',
    usersWithPurchaseReport: '/admin/report/orderUsers',
    registeredUsers: '/admin/report/registeredUsers',
    dailyOrders: '/admin/report/dailyOrders',
    orderPayments: '/admin/report/orderPayments',
    newUsrCompReport: '/admin/report/newUsersCompetitions',
    stockReport: '/admin/report/inventory-history',
    topupList: '/admin/top-up/list',
    topupPayments: '/admin/top-up/payments',
    cashbackOptions: '/admin/cashback/list',
    cashbackTrans: '/admin/cashback/transactions/list',
    tabConfigs: '/admin/system-config/tab-configs',
    databaseStats: '/admin/system-config/db-writer',
    databaseConfigHistory: '/admin/system-config/fail-over-history',
    databaseExist: '/admin/system-config/db-exists',
    awsS3Conf: '/admin/system-config/s3-configs',
    BannerList: '/admin/banner/list',
    competitionBanners: '/admin/banner/competition-banners',
    dailySales: '/admin/report/dailySales',
    userChannels: '/admin/report/userChannels',
    failedPayments: '/admin/report/failedPayments',
    dailyCompetitionSales: '/admin/report/competitionSales',
    reEnterWeeklyReport: '/admin/report/re-enter-weekly',
    multiPayReport: '/admin/report/multi-payment-report',
    affiliateList: '/admin/affiliate/list',
    affiliateReferrals: '/admin/affiliate/referrals',
    affiliateTransactions: '/admin/affiliate/transactions',
    affiliateReport: '/admin/report/affiliates',
    affiliateCampaigns: '/admin/affiliate/campaigns',
  },
  post: {
    createAdmin: '/admin/create/admin',
    resetPass: '/admin/reset-pass-other',
    updateRole: '/admin/role',
    updateRolePermission: '/admin/role/permission',
    createUser: '/admin/create/user',
    updateUserDetails: '/admin/user/profile',
    resetUserPass: '/admin/user/password',
    addUserBalance: '/admin/user/balance',
    activateUser: '/admin/user/active',
    assignAffiliate: '/admin/user/assign-affiliate',
    affiliateTransactionAudit: '/admin/affiliate/transaction-audit',
    upsertQuiz: '/admin/competition/quiz-edit',
    createCompetition: '/admin/competition/create',
    updateCompetition: '/admin/competition/update',
    publishCompetition: '/admin/competition/publish',
    closeCompetition: '/admin/competition/close',
    approveCompetition: '/admin/competition/approve',
    approvalCheck: '/admin/competition/audit-check',
    approvalLog: '/admin/competition/audit-log',
    setTags: '/admin/competition/tag-edit',
    setPopUp: '/admin/competition/set-popup',
    allocateRewards: '/admin/competition/allocate-reward',
    selectWinner: '/admin/competition/winner',
    setPrize: '/admin/competition/set-prize',
    freeEntries: '/admin/competition/free-entries',
    upsertCatalog: '/admin/catalog/catalog-edit',
    updateCatalogCompetitions: '/admin/catalog/competitions-update',
    createProduct: '/admin/product/create',
    updateProductInline: '/admin/product/edit',
    refundPayment: '/admin/order/refund',
    refundWallet: '/admin/order/refund-wallet',
    updatePayment: '/admin/order/payment/update',
    generateTicket: '/admin/order/ticket',
    resendEmail: '/admin/order/email/resend',
    upsertCoupon: '/admin/coupon/upsert',
    deleteCoupon: '/admin/coupon/delete',
    createCouponCode: '/admin/coupon/code',
    sendUserCouponCode: '/admin/coupon/user',
    assignCouponCodeUser: '/admin/coupon/code/user',
    updateEntryStatus: '/admin/entry-list/publish',
    updateEntryList: '/admin/entry-list/update',
    updateEntryLink: '/admin/entry-list/update-link',
    exportEntryList: '/admin/entry-list/export',
    updateVideoDrawStatus: '/admin/draw-list/publish',
    updateVideoDrawList: '/admin/draw-list/upsert',
    updateWinnerDetail: '/admin/user-reward/update',
    convertPrize: '/admin/user-reward/convert-prize',
    contactWinner: '/admin/user-reward/contact',
    upsertNotification: '/admin/notification/upsert',
    sendNotification: '/admin/notification/send',
    upsertDonation: '/admin/donation/upsert',
    updateTopup: '/admin/top-up/edit',
    retrieveTopupPayment: '/admin/top-up/retrieve',
    updateCashbackOption: '/admin/cashback/edit',
    auditCashback: '/admin/cashback/transactions/audit',
    approveCashback: '/admin/cashback/transactions/approve',
    editSysConfigs: '/admin/system-config/config-edit', // payment method config
    databaseConfig: '/admin/system-config/fail-over',
    upsertBanner: '/admin/banner/edit',
    auditWithdraw: '/admin/cash/transaction-audit',
    approveWithdraw: '/admin/cash/profile-upsert',
  },
}

export const TIMEZONE_LIST = ['Europe/London', 'Mexico/General', 'Australia/Sydney']

export const STORE_LIST = [
  { store_id: 1, name: 'United Kingdoms', abbr: 'UK', cur: 'GBP', sign: '£' },
  { store_id: 2, name: 'Mexico', abbr: 'MEX', cur: 'MXN', sign: 'Mex$' },
]

export const IE_COUNTY_LIST = [
  'Carlow',
  'Cavan',
  'Clare',
  'Cork',
  'Donegal',
  'Dublin',
  'Galway',
  'Kerry',
  'Kildare',
  'Kilkenny',
  'Laois',
  'Leitrim',
  'Limerick',
  'Longford',
  'Louth',
  'Mayo',
  'Meath',
  'Monaghan',
  'Offaly',
  'Roscommon',
  'Sligo',
  'Tipperary',
  'Waterford',
  'Westmeath',
  'Wexford',
  'Wicklow',
]

export const MX_COUNTY_LIST = [
  'Chihuahua',
  'Sonora',
  'Coahuila',
  'Durango',
  'Oaxaca',
  'Tamaulipas',
  'Jalisco',
  'Zacatecas',
  'Baja California Sur',
  'Chiapas',
  'Veracruz',
  'Baja California',
  'Nuevo León',
  'Guerrero',
  'San Luis Potosí',
  'Michoacán',
  'Sinaloa',
  'Campeche',
  'Quintana Roo',
  'Yucatán',
  'Puebla',
  'Guanajuato',
  'Nayarit',
  'Tabasco',
  'México',
  'Hidalgo',
  'Querétaro',
  'Colima',
  'Aguascalientes',
  'Morelos',
  'Tlaxcala',
  'México City',
]

export const PRICE_OPTIONS = [
  { type: 0, amount: null, status: null, start_at: '', expired_at: '' },
  { type: 1, amount: null, status: null, start_at: '', expired_at: '' },
  { type: 2, amount: null, status: null, start_at: '', expired_at: '' },
  { type: 3, amount: null, status: null, start_at: '', expired_at: '' },
]

export const STORE_TEMPLATE = {
  id: null,
  key: null,
  value: null,
  text: null,
  competition_id: null,
  store_id: null,
  status: 0,
  start_time: '',
  end_time: '',
  default_ticket_amount: null,
  description: '',
  imgFiles: [],
  categories: [],
  catalogOpts: [],
  prices: [],
  dateValid: null,
  amtValid: null,
  priceValid: null,
  isStartChangeable: true,
  isPriceChangeable: true,
}

export const CASH_ALT_OPTIONS = [
  { currency: 'GBP', amount: null },
  { currency: 'MXN', amount: null },
]
